define("ember-concerns/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.guidForConcern = guidForConcern;
  _exports._cleanupOnDestroy = _cleanupOnDestroy;
  _exports.getConcernFactory = getConcernFactory;
  _exports.getPropertyNames = getPropertyNames;
  _exports.isElementDescriptor = isElementDescriptor;
  const {
    keys
  } = Object;
  const SEPARATOR = '__';

  function guidForConcern({
    name,
    model
  }) {
    return [name, Ember.guidFor(model)].join(SEPARATOR);
  } // Taken from `ember-concurrency`.


  function _cleanupOnDestroy(owner, object, cleanupMethodName, ...args) {
    // TODO: find a non-mutate-y, non-hacky way of doing this.
    if (!owner.willDestroy) {
      // we're running in non Ember object (possibly in a test mock)
      return;
    }

    if (!owner.willDestroy.__ember_processes_destroyers__) {
      let oldWillDestroy = owner.willDestroy;
      let disposers = [];

      owner.willDestroy = function () {
        for (let i = 0, l = disposers.length; i < l; i++) {
          disposers[i]();
        }

        oldWillDestroy.apply(owner, arguments);
      };

      owner.willDestroy.__ember_processes_destroyers__ = disposers;
    }

    owner.willDestroy.__ember_processes_destroyers__.push(() => {
      object[cleanupMethodName](...args);
    });
  }

  function getConcernFactory(parent, name) {
    let owner = Ember.getOwner(parent) || parent.container;
    (false && !(owner) && Ember.assert('An owner is necessary to create a concern instance', owner));
    let factory = owner.factoryFor(`concern:${name}`);
    (false && !(factory) && Ember.assert(`A concern with name '${name}' was not found`, factory));
    return factory;
  }

  function getPropertyNames(model) {
    if (Ember.typeOf(model.eachAttribute) === 'function') {
      let keys = [];
      model.eachAttribute(key => keys.push(key));
      return keys;
    }

    return keys(model);
  } // Taken from `@ember/-internals/metal`.


  function isElementDescriptor(args) {
    let [maybeTarget, maybeKey, maybeDesc] = args;
    return (// Ensure we have the right number of args
      args.length === 3 && ( // Make sure the target is a class or object (prototype)
      typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) && // Make sure the key is a string
      typeof maybeKey === 'string' && ( // Make sure the descriptor is the right shape
      typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc || // TS compatibility
      maybeDesc === undefined)
    );
  }
});