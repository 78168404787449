define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.1 11.7H8.5v-1.3h1.6c.2 0 .4-.2.4-.4V8.5h1.3v1.6c-.1.9-.8 1.6-1.7 1.6zm-4.8 0H2.7c-.9 0-1.7-.7-1.7-1.6V2.7C1 1.8 1.8 1 2.7 1h7.4c.9 0 1.7.8 1.7 1.7v2.6h-1.3V2.7c0-.2-.2-.4-.4-.4H2.7c-.2 0-.4.2-.4.4v7.4c0 .2.2.4.4.4h2.6v1.2z\"/><path d=\"M15.3 17H7.9c-.9 0-1.7-.8-1.7-1.7V7.9c.1-.9.8-1.6 1.7-1.6h7.4c.9 0 1.7.7 1.7 1.6v7.4c0 .9-.8 1.7-1.7 1.7zM7.9 7.5c-.2 0-.4.2-.4.4v7.4c0 .2.2.4.4.4h7.4c.2 0 .4-.2.4-.4V7.9c0-.2-.2-.4-.4-.4H7.9z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});