define("ember-svg-jar/inlined/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.5 20h-1c-.9 0-1.7-.7-1.7-1.7v-.7c-.1 0-.2-.1-.3-.1L9 18c-.6.6-1.7.6-2.3 0l-.7-.7c-.3-.3-.5-.7-.5-1.2 0-.4.2-.9.5-1.2l.5-.5c0-.1-.1-.2-.1-.3h-.7c-.9 0-1.7-.7-1.7-1.7v-1c0-.9.7-1.7 1.7-1.7h.7c0-.1.1-.2.1-.3L6 9c-.3-.3-.5-.7-.5-1.1s.2-.9.5-1.2l.7-.7c.6-.6 1.7-.6 2.3 0l.5.5c.1 0 .2-.1.3-.1v-.7c0-.9.7-1.7 1.7-1.7h1c.9 0 1.7.7 1.7 1.7v.7c.1 0 .2.1.3.1L15 6c.6-.6 1.7-.6 2.3 0l.7.7c.3.3.5.7.5 1.2s-.2.8-.5 1.1l-.5.5c0 .1.1.2.1.3h.7c.9 0 1.7.7 1.7 1.7v1c0 .9-.7 1.7-1.7 1.7h-.7c0 .1-.1.2-.1.3l.5.5c.3.3.5.7.5 1.2 0 .4-.2.9-.5 1.2l-.7.6c-.6.6-1.7.6-2.3 0l-.5-.5c-.1 0-.2.1-.3.1v.7c-.1 1-.8 1.7-1.7 1.7zm-3.2-3.9l.4.2c.3.2.6.3.9.4l.4.1v1.5c0 .3.2.5.5.5h1c.3 0 .5-.2.5-.5v-1.5l.4-.1c.3-.1.6-.2.9-.4l.4-.2 1.1 1.1c.2.2.5.2.7 0l.7-.7c.2-.2.2-.5 0-.7l-1.1-1.1.2-.4c.2-.3.3-.6.4-.9l.1-.4h1.5c.3 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5h-1.5l-.1-.4c-.1-.3-.2-.6-.4-.9l-.2-.4 1.1-1.1c.2-.2.2-.5 0-.7l-.7-.7c-.2-.2-.5-.2-.7 0l-1.1 1.1-.4-.2c-.3-.2-.6-.3-.9-.4l-.4-.1V5.7c0-.3-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5v1.5l-.4.1c-.3.1-.6.2-.9.4l-.4.2-1.1-1.1c-.2-.2-.5-.2-.7 0l-.7.7c-.2.2-.2.5 0 .7l1.1 1.1-.2.4c-.2.3-.3.6-.4.9l-.1.4H5.7c-.3 0-.5.2-.5.5v1c0 .3.2.5.5.5h1.5l.1.4c.1.3.2.6.4.9l.2.4-1.1 1.1c-.2.2-.2.5 0 .7l.7.7c.2.2.5.2.7 0l1.1-1.1zm2.7-1.2c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9zm0-4.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});