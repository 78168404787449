define("ember-svg-jar/inlined/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.3 12.7c-.4-1-1.5-1.1-1.8-1.1-1.1-.2-2.5-2.4-3.1-3.8-1.1-2.2-3.3-3.7-5.7-3.9C9.1 2.6 7.5 2 6.2 2.6c-.7.3-1.2.8-1.5 1.5-.3.6-.2 1.4 0 2C3.3 7.9 3 10.3 4 12.7c.6 1.3 1.4 3.8.8 4.7-.1.1-.8 1-.5 2 .1.4.4.7.8.9.3.1.6.2 1 .2s.9-.1 1.4-.3l2.9-1.2c0 .1 0 .2.1.3.4 1 1.4 1.6 2.5 1.6.4 0 .7-.1 1.1-.2.7-.3 1.2-.8 1.4-1.5.3-.7.2-1.4 0-2 0-.1-.1-.1-.1-.2l3.3-1.4c.8-.3 1.4-.9 1.6-1.5.2-.6.2-1 0-1.4zm-5.9 4.9c.2.4.2.7 0 1.1-.1.4-.4.7-.8.8-.8.3-1.7 0-2-.8 0-.1-.1-.2-.1-.2l2.6-1.1.1-.1c.1.1.2.2.2.3zm4.8-4.1c-.1.3-.4.6-1 .9l-3.6 1.5-.8.4-6.7 2.8c-.6.2-1.1.2-1.4.1-.1 0-.2-.1-.2-.2-.1-.4.2-.8.3-.8 1.2-1.8-.4-5.5-.7-5.9-.9-2.2-.6-4.3.8-5.7l.3-.4-.2-.4s-.1-.1-.1-.2c-.2-.4-.2-.7 0-1.1.1-.3.4-.6.8-.8.8-.3 1.7 0 2 .8 0 .1 0 .1.1.2l.1.5h.5c2-.1 4 1.2 5 3.2.2.4 1.9 4.1 4.1 4.5.3 0 .7.1.8.4 0 0-.1.1-.1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});