define("ember-svg-jar/inlined/user-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 12.2c.4-.8.6-1.8.6-2.8 0-3-2.1-5.5-4.6-5.5S5.4 6.4 5.4 9.4c0 1 .2 1.9.6 2.7-2.9 1.1-4.6 3.2-4.6 5.7 0 1.1 1.7 1.6 3 1.8 1.5.3 3.4.4 5.6.4 3.2 0 8.6-.3 8.6-2.2-.1-2.4-1.8-4.6-4.6-5.6zm-4-7.1c1.9 0 3.4 1.9 3.4 4.3s-1.5 4.3-3.4 4.3-3.4-1.9-3.4-4.3S8.1 5.1 10 5.1zm0 13.7c-4.4 0-7.1-.6-7.4-1 0-2 1.6-3.8 4.1-4.6.8 1 2 1.7 3.3 1.7 1.3 0 2.5-.6 3.3-1.7 2.5.9 4 2.6 4 4.5-.2.5-3 1.1-7.3 1.1zm7.9-13.7h5c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-5c-.3 0-.6.3-.6.6s.3.6.6.6zm5 1.8h-5c-.3 0-.6.3-.6.6s.3.6.6.6h5c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zm0 3h-5c-.3 0-.6.3-.6.6s.3.6.6.6h5c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});