define("ember-svg-jar/inlined/chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 12.94l-5 5.32a.77.77 0 11-1.12-1.05L14.72 12 9.89 6.78a.76.76 0 010-1.08.77.77 0 011.08 0l4.93 5.35a1.28 1.28 0 01.37.93 1.31 1.31 0 01-.27.96zm-1.1-1.07zm0 .29z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});