define("ember-svg-jar/inlined/globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 3.4c-4.7 0-8.6 3.9-8.6 8.6s3.9 8.6 8.6 8.6 8.6-3.9 8.6-8.6-3.9-8.6-8.6-8.6zm7.4 7.9h-3.8c-.1-2.5-.6-4.9-1.5-6.4 2.9.9 5 3.4 5.3 6.4zM12 19.4c-1 0-2.3-2.6-2.4-6.9h4.8c-.1 4.3-1.4 6.9-2.4 6.9zm-2.4-8.1C9.7 7.1 11 4.6 12 4.6s2.3 2.5 2.4 6.7H9.6zm.3-6.4c-.9 1.5-1.4 4-1.5 6.4H4.6c.3-3 2.4-5.5 5.3-6.4zm-5.3 7.6h3.8c.1 2.5.6 5 1.5 6.6-2.9-.9-5-3.4-5.3-6.6zm9.5 6.6c.9-1.5 1.4-4.1 1.5-6.6h3.8c-.3 3.2-2.4 5.7-5.3 6.6z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});