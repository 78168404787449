define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 20.7c-4.8 0-8.6-3.9-8.6-8.7S7.2 3.4 12 3.4s8.7 3.9 8.7 8.6-3.9 8.7-8.7 8.7zm0-16.1c-4.1 0-7.4 3.3-7.4 7.4s3.3 7.3 7.4 7.3 7.3-3.3 7.3-7.3-3.2-7.4-7.3-7.4z\"/><path d=\"M10.5 15.4L8 13l1-1 1.5 1.6 4-4.1 1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});