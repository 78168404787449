define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 19.6h-7c-.9 0-1.6-.7-1.6-1.6v-2.5h1.2V18c0 .2.2.4.4.4h7c.2 0 .4-.2.4-.4v-7c0-.2-.2-.4-.4-.4h-2.5V9.4H18c.9 0 1.6.7 1.6 1.6v7c0 .9-.7 1.6-1.6 1.6zm-7.4-7.1H9.4V11c0-.9.7-1.6 1.6-1.6h1.5v1.2H11c-.2 0-.4.2-.4.4v1.5z\"/><path d=\"M13 14.6H6c-.9 0-1.6-.7-1.6-1.6V6c0-.9.7-1.6 1.6-1.6h7c.9 0 1.6.7 1.6 1.6v7c0 .9-.7 1.6-1.6 1.6zm-7-9c-.2 0-.4.2-.4.4v7c0 .2.2.4.4.4h7c.2 0 .4-.2.4-.4V6c0-.2-.2-.4-.4-.4H6z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});