define("ember-svg-jar/inlined/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.5 18.6h-15c-.9 0-1.6-.7-1.6-1.6V7c0-.9.7-1.6 1.6-1.6h15c.9 0 1.6.7 1.6 1.6v10c0 .9-.7 1.6-1.6 1.6zm-15-12c-.2 0-.4.2-.4.4v10c0 .2.2.4.4.4h15c.2 0 .4-.2.4-.4V7c0-.2-.2-.4-.4-.4h-15z\"/><path d=\"M12 13.4c-.3 0-.7-.1-.9-.3L6.2 9.5c-.3-.2-.3-.6-.1-.9.2-.3.6-.3.8-.1l4.9 3.6c.1.1.3.1.5 0l4.9-3.6c.3-.2.6-.1.8.1.2.3.1.6-.1.8L13 13c-.3.3-.7.4-1 .4z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});