define("ember-svg-jar/inlined/star-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 10.1c-.1-.2-.2-.4-.4-.4l-4.6-1-2.4-4c-.2-.4-.8-.4-1.1 0L9 8.7l-4.5 1c-.2.1-.4.2-.5.4-.1.2 0 .4.1.6l3.1 3.6-.4 4.7c0 .2.1.4.3.6.2.1.4.2.6.1l4.3-1.8 4.3 1.8h.2c.1 0 .3 0 .4-.1.2-.1.3-.3.3-.6l-.4-4.7 3.1-3.6c.1-.1.1-.4.1-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});