define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.2 19l-3-3.1c-.1-.1-.1-.1-.2-.1 1.4-1.2 2.2-3 2.2-5 .1-3.8-3-6.8-6.7-6.8s-6.8 3-6.8 6.7 3.1 6.7 6.8 6.7c1.4 0 2.7-.4 3.8-1.1 0 .1.1.2.1.3l3 3.1c.1.1.3.2.4.2.1 0 .3-.1.4-.2.2-.1.3-.5 0-.7zM5.8 10.7c0-3.1 2.5-5.5 5.7-5.5 3.1 0 5.7 2.5 5.7 5.5s-2.5 5.5-5.7 5.5c-3.2.1-5.7-2.4-5.7-5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});