define("ember-svg-jar/inlined/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"st0\" d=\"M4.6 6h14.8c.3 0 .6.3.6.6s-.3.6-.6.6H4.6c-.3 0-.6-.3-.6-.6s.3-.6.6-.6zm0 5h14.8c.3 0 .6.3.6.6s-.3.6-.6.6H4.6c-.3 0-.6-.3-.6-.6s.3-.6.6-.6zm0 5h14.8c.3 0 .6.3.6.6s-.3.6-.6.6H4.6c-.3 0-.6-.3-.6-.6s.3-.6.6-.6z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});