define("ember-basic-dropdown/components/basic-dropdown-trigger", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BasicDropdownTrigger = (_class = (_temp = class BasicDropdownTrigger extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "toggleIsBeingHandledByTouchEvents", false);

      _defineProperty(this, "hasMoved", false);
    }

    // Actions
    handleMouseDown(e) {
      if (this.args.dropdown.disabled) {
        return;
      }

      if (this.args.eventType !== 'mousedown' || e.button !== 0) return;

      if (this.args.stopPropagation) {
        e.stopPropagation();
      }

      this._stopTextSelectionUntilMouseup();

      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }

      this.args.dropdown.actions.toggle(e);
    }

    handleClick(e) {
      if (typeof document === 'undefined') return;

      if (this.isDestroyed || !this.args.dropdown || this.args.dropdown.disabled) {
        return;
      }

      if (this.args.eventType !== undefined && this.args.eventType !== 'click' || e.button !== 0) return;

      if (this.args.stopPropagation) {
        e.stopPropagation();
      }

      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }

      this.args.dropdown.actions.toggle(e);
    }

    handleKeyDown(e) {
      if (this.args.dropdown.disabled) {
        return;
      }

      if (e.keyCode === 13) {
        // Enter
        this.args.dropdown.actions.toggle(e);
      } else if (e.keyCode === 32) {
        // Space
        e.preventDefault(); // prevents the space to trigger a scroll page-next

        this.args.dropdown.actions.toggle(e);
      } else if (e.keyCode === 27) {
        this.args.dropdown.actions.close(e);
      }
    }

    handleTouchStart() {
      document.addEventListener('touchmove', this._touchMoveHandler);
    }

    handleTouchEnd(e) {
      this.toggleIsBeingHandledByTouchEvents = true;

      if (e && e.defaultPrevented || this.args.dropdown.disabled) {
        return;
      }

      if (!this.hasMoved) {
        this.args.dropdown.actions.toggle(e);
      }

      this.hasMoved = false;
      document.removeEventListener('touchmove', this._touchMoveHandler); // This next three lines are stolen from hammertime. This prevents the default
      // behaviour of the touchend, but synthetically trigger a focus and a (delayed) click
      // to simulate natural behaviour.

      let target = e.target;

      if (target !== null) {
        target.focus();
      }

      setTimeout(function () {
        if (!e.target) {
          return;
        }

        try {
          let event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          e.target.dispatchEvent(event);
        } catch (e) {
          event = new Event('click');
          e.target.dispatchEvent(event);
        }
      }, 0);
      e.preventDefault();
    }

    removeGlobalHandlers() {
      if (typeof document === 'undefined') return;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      document.removeEventListener('mouseup', this._mouseupHandler, true);
    }

    _mouseupHandler() {
      document.removeEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.remove('ember-basic-dropdown-text-select-disabled');
    }

    _touchMoveHandler() {
      this.hasMoved = true;
      document.removeEventListener('touchmove', this._touchMoveHandler);
    } // Methods


    _stopTextSelectionUntilMouseup() {
      document.addEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.add('ember-basic-dropdown-text-select-disabled');
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "handleMouseDown", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchStart", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchEnd", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeGlobalHandlers", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "removeGlobalHandlers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_mouseupHandler", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "_mouseupHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_touchMoveHandler", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "_touchMoveHandler"), _class.prototype)), _class);
  _exports.default = BasicDropdownTrigger;
});