define("ember-svg-jar/inlined/mute-conversation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.6 8.4c-.3.1-.5.5-.4.9.2.5.3 1.1.3 1.6 0 2.2-1.7 4.1-3.8 4.3-.1 0-.2.1-.3.2-.2.1-.3.3-.3.6v2.5l-2-2.6c-.2-.3-.7-.3-.9-.1-.3.2-.3.7-.1.9l2.2 2.8c.2.3.6.5 1 .5.7 0 1.2-.6 1.2-1.2v-2.3c2.6-.6 4.5-2.9 4.5-5.6 0-.7-.1-1.4-.4-2.1-.3-.4-.7-.6-1-.4zM6 15.2c.1.1.3.2.4.2.2 0 .4-.1.5-.2.2-.3.2-.7-.1-1-1-.8-1.5-2.1-1.5-3.3 0-2.4 2-4.4 4.4-4.4h3.4c.6 0 1.1.1 1.6.3.3.1.7 0 .9-.4.1-.3 0-.7-.4-.9-.7-.3-1.4-.4-2.1-.4H9.8C6.6 5.1 4 7.7 4 10.9c0 1.6.8 3.2 2 4.3zm13.8-11c-.3-.3-.7-.3-1 0L4.2 18.8c-.3.3-.3.7 0 1 .2.1.3.2.5.2s.3-.1.5-.2L19.8 5.2c.2-.3.2-.7 0-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});