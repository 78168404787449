define("ember-svg-jar/inlined/back-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.305 6.15l3.377 3.726a.65.65 0 11-.964.873L.704 6.319a1.067 1.067 0 01-.002-1.585L4.716.254a.65.65 0 11.968.867L2.344 4.85h13.878a.65.65 0 110 1.3z\"/>",
    "attrs": {
      "height": "11",
      "viewBox": "0 0 17 11",
      "width": "17",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});