define("ember-svg-jar/inlined/chevron-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 14.6c-.3 0-.5-.1-.7-.3l-4.2-3.9c-.2-.2-.3-.6 0-.8.2-.2.6-.3.8 0l4.1 3.8 4.1-3.8c.2-.2.6-.2.8 0 .2.2.2.6 0 .8l-4.2 3.9c-.1.2-.4.3-.7.3zm-.1-1.1zm.2-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});