define("ember-svg-jar/inlined/placeholder-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#2E405C\" d=\"M36.4 0H3.6C1.6 0 0 1.6 0 3.6V28c0 2 1.6 3.6 3.6 3.6h32.8c2 0 3.6-1.6 3.6-3.6V3.6c0-2-1.6-3.6-3.6-3.6zm.4 27.9c0 .3-.2.5-.5.5H3.7c-.3 0-.5-.2-.5-.5v-1.4l7.7-6.9c.5-.5 1.2-.7 1.8-.7.7 0 1.3.3 1.8.8l5.2 5.7c1.2 1.4 3.3 1.6 4.8.5l4.2-3.1c.9-.7 2.2-.7 3.1 0l5.1 3.8v1.3zm0-4.6l-3.3-2.5c-2-1.5-4.7-1.5-6.7 0L22.6 24c-.2.2-.5.2-.7 0l-5.2-5.9c-1-1.1-2.4-1.8-3.9-1.9s-2.9.4-4.1 1.5L3 22.9V3.7c0-.3.2-.5.5-.5h32.7c.3 0 .5.2.5.5v19.6z\"/><circle fill=\"#2E405C\" cx=\"29\" cy=\"8.9\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 32"
    }
  };
  _exports.default = _default;
});