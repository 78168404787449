define("ember-svg-jar/inlined/new-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M31.6 24.5c0 .4-.3.7-.7.7H9.7c-.4 0-.7-.3-.7-.7V10.4c0-.4.3-.7.7-.7h12.6V8.3H9.7c-1.2 0-2.1.9-2.1 2.1v14.1c0 1.2.9 2.1 2.1 2.1h21.2c1.2 0 2.1-.9 2.1-2.1V18h-1.4v6.5z\"/><path d=\"M19.9 17.7l-6.9-5c-.3-.2-.8-.2-1 .1-.2.3-.2.8.1 1l6.9 5c.4.3.8.4 1.2.4s.9-.1 1.2-.4l4.4-3.2c.3-.2.4-.7.2-1-.2-.3-.7-.4-1-.2l-4.4 3.2c-.1.3-.5.3-.7.1zM32.3 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14.6c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-3 6.6-6.6 6.6z\"/><path d=\"M36.3 7.3H33V4c0-.4-.3-.7-.7-.7s-.7.3-.7.7v3.3h-3.3c-.4 0-.7.3-.7.7s.3.7.7.7h3.3V12c0 .4.3.7.7.7s.7-.3.7-.7V8.7h3.3c.4 0 .7-.3.7-.7s-.3-.7-.7-.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 35",
      "height": "35",
      "width": "40",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});