define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 20c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-1.3a6.7 6.7 0 100-13.4 6.7 6.7 0 000 13.4zm-.5-7.8h1.1c.3 0 .5.2.5.5v4.2c0 .3-.2.5-.5.5h-1.1c-.3 0-.5-.2-.5-.5v-4.2c-.1-.2.2-.5.5-.5zm0-3.1h1.1c.3 0 .5.2.5.5v1.1c0 .3-.2.5-.5.5h-1.1c-.3 0-.5-.2-.5-.5V8.3c-.1-.3.2-.5.5-.5z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});