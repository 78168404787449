define("ember-svg-jar/inlined/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.1 4h-7.4c-.3 0-.6.3-.6.6s.3.6.6.6h6.7v13.5h-6.7c-.3 0-.6.3-.6.6s.3.6.6.6h7.4c.3 0 .6-.3.6-.6V4.6c0-.3-.3-.6-.6-.6z\"/><path d=\"M11.2 15.3c-.2.3-.2.7.1.9.1.1.3.2.4.2.2 0 .3-.1.5-.2l3-3.4c.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7l-3-3.4c-.2-.3-.6-.3-.9-.1-.3.2-.3.6-.1.8l2.3 2.6H4.9c-.3 0-.6.3-.6.6s.3.6.6.6h8.6l-2.3 2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});