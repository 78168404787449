define("ember-concerns/helpers/concern", ["exports", "ember-lifeline", "ember-concerns/utils"], function (_exports, _emberLifeline, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countFor = countFor;
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    concernFor({
      name,
      model
    }) {
      let factory = (0, _utils.getConcernFactory)(model, name);
      return this.createConcern({
        factory,
        name,
        model
      });
    },

    createConcern({
      factory,
      name,
      model
    }) {
      let concern = factory.create({
        model
      });
      incrementCountFor({
        name,
        model
      });
      (0, _emberLifeline.registerDisposable)(this, () => {
        concern.destroy();
        decrementCountFor({
          name,
          model
        });
      });
      return concern;
    },

    compute([name, model]) {
      (false && !(typeof name === 'string') && Ember.assert(`A concern must be a string but you passed ${typeof name}`, typeof name === 'string'));
      (false && !(typeof model === 'object') && Ember.assert(`A concern must be an object but you passed ${typeof model}`, typeof model === 'object'));
      let context = {
        name,
        model
      };
      let concern = this.concernFor(context);
      return concern;
    },

    willDestroy() {
      (0, _emberLifeline.runDisposables)(this);
    }

  });

  _exports.default = _default;
  let COUNT = {};

  function countFor({
    name,
    model
  }) {
    let guid = (0, _utils.guidForConcern)({
      name,
      model
    });
    return COUNT[guid] || 0;
  }

  function incrementCountFor({
    name,
    model
  }) {
    let guid = (0, _utils.guidForConcern)({
      name,
      model
    });

    if (!COUNT[guid]) {
      COUNT[guid] = 0;
    }

    COUNT[guid]++;
  }

  function decrementCountFor({
    name,
    model
  }) {
    let guid = (0, _utils.guidForConcern)({
      name,
      model
    });
    COUNT[guid]--;
  }
});