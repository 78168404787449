define("ember-svg-jar/inlined/chevron-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.69 12a1.28 1.28 0 01.37-.93L13 5.75a.77.77 0 011.08 0 .76.76 0 010 1.08L9.28 12l4.83 5.18A.77.77 0 1113 18.26l-5-5.32a1.31 1.31 0 01-.31-.94zm1.46-.13zm0 .25z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});