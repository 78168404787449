define("ember-svg-jar/inlined/mute-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 17.86a1.56 1.56 0 01-.89-.27l-2-1.32a.6.6 0 11.66-1l2 1.33a.42.42 0 00.62-.34V12.5a.6.6 0 011.2 0v3.76a1.6 1.6 0 01-1.59 1.6zm-6.5-2.63H7a1.6 1.6 0 01-1.6-1.6v-5A1.6 1.6 0 017 7h2.57l3.54-2.36a1.6 1.6 0 012.22.44 1.58 1.58 0 01.27.92v1.18a.6.6 0 01-1.2 0V6a.34.34 0 00-.07-.22.39.39 0 00-.55-.11l-3.7 2.46a.56.56 0 01-.33.1H7a.4.4 0 00-.4.4v5A.4.4 0 007 14h.5a.6.6 0 110 1.2zM6.12 19.2a.61.61 0 01-.43-.2.6.6 0 010-.84l13-13a.58.58 0 01.84 0 .59.59 0 010 .85l-13 13a.58.58 0 01-.41.19z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});