define("ember-svg-jar/inlined/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.8 15.9c-.5 0-1-.3-1.2-.8-.1-.3-.1-.7 0-1l.9-2.4c.1-.2.2-.3.3-.5l9-9c.6-.6 1.6-.6 2.1 0l1.2 1.2c.3.3.5.7.5 1.1 0 .4-.2.8-.4 1.1l-9 9c-.1.1-.3.2-.5.3l-2.4.9c-.1.1-.3.1-.5.1zm1.2-3.6l-.6 1.7 1.8-.7L21 4.5l-1-1-9 8.8zm1.2 1z\"/><path d=\"M19.4 22.1H3.8c-1.2 0-2.1-.9-2.1-2.1V4.5c0-1.2.9-2.1 2.1-2.1h9.9c.4 0 .8.4.8.8s-.4.8-.8.8H3.8c-.3 0-.5.2-.5.5V20c0 .3.2.5.5.5h15.6c.3 0 .5-.2.5-.5v-8.3c0-.4.4-.8.8-.8s.8.4.8.8V20c0 1.1-.9 2.1-2.1 2.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});