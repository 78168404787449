define("ember-svg-jar/inlined/badge-repost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.6 8.6L11 7c-.2-.2-.6-.2-.8 0-.2.2-.2.6 0 .8l.5.5H9.2c-2.1 0-3.8 1.9-3.8 4.1s1.7 4.1 3.8 4.1c.3 0 .6-.3.6-.6s-.3-.6-.6-.6c-1.4 0-2.6-1.3-2.6-2.9 0-1.6 1.2-2.9 2.6-2.9h1.6l-.7.7c-.2.2-.2.6 0 .8.1.1.3.2.4.2.2 0 .3-.1.4-.2l1.6-1.7c.3-.1.3-.4.1-.7zm2.2-.2c-.3 0-.6.3-.6.6s.3.6.6.6c1.4 0 2.6 1.3 2.6 2.9 0 1.6-1.2 2.9-2.6 2.9h-1.6l.7-.7c.2-.2.2-.6 0-.8-.2-.2-.6-.2-.8 0l-1.6 1.7c-.2.2-.2.6 0 .8L13 18c.1.1.3.2.4.2.2 0 .3-.1.4-.2.2-.2.2-.6 0-.8l-.5-.5h1.4c2.1 0 3.8-1.9 3.8-4.1s-1.6-4.2-3.7-4.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});