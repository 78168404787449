define("ember-svg-jar/inlined/chats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h24v24H0z\"/><path d=\"M16.036 7.28c.996 0 1.804.808 1.804 1.804h0v6.181c0 .996-.808 1.804-1.804 1.804h0-3.732l-4.242 4.582a.451.451 0 01-.782-.307V17.07H3.804a1.804 1.804 0 01-1.798-1.656L2 15.265V9.084c0-.996.808-1.804 1.804-1.804h0zM4.75 10.8h8.8M4.75 13.55h5.5\" stroke=\"#2E405C\" stroke-width=\"1.2\" stroke-linejoin=\"round\"/><path d=\"M19.996 11.79c.996 0 1.804-.809 1.804-1.805V3.804C21.8 2.808 20.992 2 19.996 2H7.764c-.996 0-1.804.808-1.804 1.804V5.3\" stroke=\"#2E405C\" stroke-width=\"1.2\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});