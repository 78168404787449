define("ember-svg-jar/inlined/block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.8 7.1c-.3-.3-.5-.6-.8-.8-1.3-1.1-3-1.8-4.9-1.8-4.2 0-7.6 3.4-7.6 7.6s3.4 7.6 7.6 7.6 7.6-3.4 7.6-7.6c-.1-2-.8-3.7-1.9-5zM5.6 12c0-3.5 2.9-6.4 6.4-6.4 1.5 0 3 .6 4.1 1.5l-9 9C6.2 15 5.6 13.5 5.6 12zm6.4 6.4c-1.5 0-3-.6-4.1-1.5l9-9c.9 1.1 1.5 2.5 1.5 4.1 0 3.5-2.9 6.4-6.4 6.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});