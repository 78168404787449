define("ember-trap-focus/components/focus-trap", ["exports", "@glimmer/component", "ember-keyboard-shortcuts", "ember-app-scheduler", "ember-trap-focus/utils/element-is-visible", "ember-trap-focus/utils/focusable-elements"], function (_exports, _component, _emberKeyboardShortcuts, _emberAppScheduler, _elementIsVisible, _focusableElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{did-insert this.handleDidInsert}}
    ...attributes
  >
    {{yield}}
  </div>
  
  */
  {"id":"zaSv02bt","block":"{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[9,\"div\",false],[15,1],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[\"handleDidInsert\"]]],null],[10],[1,1,0,0,\"\\n  \"],[16,2,null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}","meta":{"moduleName":"ember-trap-focus/components/focus-trap.hbs"}});

  let FocusTrap = (_class = (_temp = class FocusTrap extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "keyboardShortcuts", {
        'alt+tab': this.previous,
        'end': this.focusLastItem,
        'home': this.focusFirstItem,
        'shift+tab': this.previous,
        down: this.next,
        tab: this.next,
        up: this.previous
      });

      _defineProperty(this, "focusTrapContainerElement", null);
    }

    async handleDidInsert(focusTrapContainerElement) {
      await (0, _emberAppScheduler.whenRouteIdle)();
      this.focusTrapContainerElement = focusTrapContainerElement;
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    }

    willDestroy() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    }

    getAllFocusableChildren() {
      let focusables = [...this.focusTrapContainerElement.querySelectorAll(_focusableElements.default)]; // NodeList to Array

      return focusables.filter(focusable => (0, _elementIsVisible.default)(focusable));
    }

    getCurrentlyFocusedItem() {
      let currentlyFocused = document.activeElement;

      if (currentlyFocused && !this.focusTrapContainerElement.contains(currentlyFocused)) {
        return null;
      }

      return currentlyFocused;
    }

    focusFirstItem(e) {
      e.stopPropagation();
      e.preventDefault();
      let focusables = this.getAllFocusableChildren();
      focusables[0] && focusables[0].focus();
    }

    focusLastItem(e) {
      e.stopPropagation();
      e.preventDefault();
      let focusables = this.getAllFocusableChildren();
      focusables[focusables.length - 1].focus();
    }

    next(e) {
      e.stopPropagation();
      e.preventDefault();
      let focusables = this.getAllFocusableChildren();
      let currentlyFocusedItem = this.getCurrentlyFocusedItem(); // if focus is not within the focuables, focus the first one.

      if (!currentlyFocusedItem) {
        focusables[0] && focusables[0].focus();
        return;
      }

      let currentlyFocusedIndex = focusables.indexOf(currentlyFocusedItem); // If we have focus on the last one, give focus on the first.

      if (focusables.length - 1 === currentlyFocusedIndex) {
        focusables[0] && focusables[0].focus();
        return;
      } // Focus the next one.


      focusables[currentlyFocusedIndex + 1] && focusables[currentlyFocusedIndex + 1].focus();
    }

    previous(e) {
      e.stopPropagation();
      e.preventDefault();
      let focusables = this.getAllFocusableChildren();
      let currentlyFocusedItem = this.getCurrentlyFocusedItem(); // If focus is not within the focusables, focus the last one

      if (!currentlyFocusedItem) {
        focusables[focusables.length - 1].focus();
        return;
      }

      let currentlyFocusedIndex = focusables.indexOf(currentlyFocusedItem); // If we have focus on the first one, wrap to the end one.

      if (currentlyFocusedIndex === 0) {
        focusables[focusables.length - 1] && focusables[focusables.length - 1].focus();
        return;
      } // Focus the previous one.


      focusables[currentlyFocusedIndex - 1] && focusables[currentlyFocusedIndex - 1].focus();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "handleDidInsert", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDidInsert"), _class.prototype)), _class);
  _exports.default = FocusTrap;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FocusTrap);
});