define("ember-svg-jar/inlined/emoji-react", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.4 11.4c-.3 0-.6.2-.6.6 0 3.8-3.1 6.9-6.9 6.9C8.1 18.9 5 15.8 5 12c0-3.8 3.1-6.9 6.9-6.9.9 0 1.8.2 2.7.5.3.1.6 0 .7-.3.1-.3 0-.6-.3-.7-1-.4-2-.6-3.1-.6-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8c.1-.3-.2-.6-.5-.6z\"/><path d=\"M8.3 12.4c-.3 0-.6.2-.6.6 0 2.4 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3c0-.3-.2-.6-.6-.6H8.3zm3.7 3.7c-1.5 0-2.8-1.1-3.1-2.6h6.2c-.3 1.4-1.6 2.6-3.1 2.6z\"/><circle cx=\"9.2\" cy=\"9.2\" r=\".9\"/><circle cx=\"14.8\" cy=\"9.2\" r=\".9\"/><path d=\"M21.3 6.3H20V5c0-.3-.2-.6-.6-.6s-.6.3-.6.6v1.3h-1.3c-.3 0-.6.2-.6.6s.2.6.6.6h1.3v1.3c0 .3.2.6.6.6s.6-.4.6-.7V7.4h1.3c.3 0 .6-.2.6-.6s-.3-.5-.6-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});