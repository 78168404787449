define("ember-user-performance-monitoring/templates/components/monitor-render", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rqS99duy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,11,4,[27,[26,1,\"CallHead\"],[]],null,[[\"key\"],[[27,[26,0,\"Expression\"],[]]]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"key\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-user-performance-monitoring/templates/components/monitor-render.hbs"
    }
  });

  _exports.default = _default;
});