define("ember-router-scroll/index", ["exports", "ember-app-scheduler", "ember-router-scroll/utils/scrollbar-width"], function (_exports, _emberAppScheduler, _scrollbarWidth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ATTEMPTS = 0;
  const MAX_ATTEMPTS = 100; // rAF runs every 16ms ideally, so 60x a second

  let requestId;
  let scrollBarWidth = 0;
  /**
   * By default, we start checking to see if the document height is >= the last known `y` position
   * we want to scroll to.  This is important for content heavy pages that might try to scrollTo
   * before the content has painted
   *
   * @method tryScrollRecursively
   * @param {Function} fn
   * @param {Object} scrollHash
   * @void
   */

  function tryScrollRecursively(fn, scrollHash) {
    const body = document.body;
    const html = document.documentElement; // read DOM outside of rAF

    const documentWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const {
      innerHeight,
      innerWidth
    } = window;
    requestId = window.requestAnimationFrame(() => {
      // write DOM (scrollTo causes reflow)
      if (documentWidth + scrollBarWidth - innerWidth >= scrollHash.x && documentHeight + scrollBarWidth - innerHeight >= scrollHash.y || ATTEMPTS >= MAX_ATTEMPTS) {
        ATTEMPTS = 0;
        fn.call(null, scrollHash.x, scrollHash.y);
      } else {
        ATTEMPTS++;
        tryScrollRecursively(fn, scrollHash);
      }
    });
  }

  let EmberRouterScroll = (_dec = Ember.inject.service('router-scroll'), (_class = (_temp = class EmberRouterScroll extends Ember.Router {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "service", _descriptor, this);
    }

    get isFastBoot() {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }

    init() {
      super.init(...arguments);
      (0, _emberAppScheduler.setupRouter)(this);
      this.on('routeWillChange', () => {
        this._routeWillChange();
      });
      this.on('routeDidChange', transition => {
        this._routeDidChange(transition);
      });

      if (!Ember.get(this, 'isFastBoot')) {
        scrollBarWidth = (0, _scrollbarWidth.getScrollBarWidth)();
      }
    }

    destroy() {
      (0, _emberAppScheduler.reset)();

      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }

      super.destroy(...arguments);
    }
    /**
     * Updates the scroll position
     * it will be a single transition
     * @method updateScrollPosition
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     * @param {Boolean} recursiveCheck -  if "true", check until document height is >= y. `y` is the last coordinate the target page was on
     */


    updateScrollPosition(transition, recursiveCheck) {
      const url = Ember.get(this, 'currentURL');
      const hashElement = url ? document.getElementById(url.split('#').pop()) : null;

      if (Ember.get(this, 'service.isFirstLoad')) {
        Ember.get(this, 'service').unsetFirstLoad();
      }

      let scrollPosition;

      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = {
          x: hashElement.offsetLeft,
          y: hashElement.offsetTop
        };
      } else {
        scrollPosition = Ember.get(this, 'service.position');
      }

      let preserveScrollPosition = (Ember.get(transition, 'router.currentRouteInfos') || []).some(routeInfo => Ember.get(routeInfo, 'route.controller.preserveScrollPosition')); // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.

      if (!preserveScrollPosition) {
        preserveScrollPosition = Ember.get(this, 'service.preserveScrollPosition');
      }

      if (!preserveScrollPosition) {
        const scrollElement = Ember.get(this, 'service.scrollElement');
        const targetElement = Ember.get(this, 'service.targetElement');

        if (targetElement || 'window' === scrollElement) {
          if (recursiveCheck) {
            // our own implementation
            tryScrollRecursively(window.scrollTo, scrollPosition);
          } else {
            // using ember-app-scheduler
            window.scrollTo(scrollPosition.x, scrollPosition.y);
          }
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));

          if (element) {
            element.scrollLeft = scrollPosition.x;
            element.scrollTop = scrollPosition.y;
          }
        }
      }
    }

    _routeWillChange() {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      Ember.get(this, 'service').update();
    }

    _routeDidChange(transition) {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      const delayScrollTop = Ember.get(this, 'service.delayScrollTop');
      const scrollWhenPainted = Ember.get(this, 'service.scrollWhenPainted');
      const scrollWhenIdle = Ember.get(this, 'service.scrollWhenIdle');

      if (!delayScrollTop && !scrollWhenPainted && !scrollWhenIdle) {
        // out of the 3 options, this happens on the tightest schedule
        const callback = function callback() {
          this.updateScrollPosition(transition, true);
        };

        Ember.run.scheduleOnce('render', this, callback);
      } else if (scrollWhenPainted) {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after First Meaningful Paint.
        // If you loading your routes progressively, this may be a good option to delay scrollTop until the remaining DOM elements are painted.
        (0, _emberAppScheduler.whenRoutePainted)().then(() => {
          this.updateScrollPosition(transition);
        });
      } else {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after the route is idle
        (0, _emberAppScheduler.whenRouteIdle)().then(() => {
          this.updateScrollPosition(transition);
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFastBoot", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype)), _class));
  var _default = EmberRouterScroll;
  _exports.default = _default;
});