define("ember-svg-jar/inlined/settings-sliders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21 11.9h-4.5c-.3-.9-1.1-1.5-2-1.5s-1.8.6-2 1.5H3v1.3h9.5c.3.9 1.1 1.5 2 1.5s1.8-.6 2-1.5H21v-1.3zm-6.5 1.4c-.5 0-.8-.4-.8-.8s.4-.9.8-.9.8.4.8.9-.3.8-.8.8zM21 17.8H9.5c-.3-.9-1.1-1.5-2-1.5s-1.8.6-2 1.5H3v1.3h2.5c.3.9 1.1 1.5 2 1.5s1.8-.6 2-1.5H21v-1.3zM7.5 19.3c-.5 0-.9-.4-.9-.8s.4-.8.9-.8.9.4.9.8-.4.8-.9.8zM21 5.9H9.5c-.2-.9-1-1.5-2-1.5s-1.8.6-2 1.5H3v1.3h2.5c.3.9 1.1 1.5 2 1.5s1.8-.6 2-1.5H21V5.9zM7.5 7.4c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});