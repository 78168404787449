define("ember-user-performance-monitoring/components/render-performance-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    userPerformanceMonitoring: Ember.inject.service(),

    triggerMonitor() {
      this.userPerformanceMonitoring.trigger('didRenderMonitorComponent');
    },

    didRender() {
      Ember.run.scheduleOnce('destroy', this, this.triggerMonitor);
    }

  });

  _exports.default = _default;
});