define("ember-svg-jar/inlined/close-chunky", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.2 12l5.4-5.4c.6-.6.6-1.6 0-2.2s-1.6-.6-2.2 0L12 9.8 6.6 4.4c-.6-.5-1.6-.5-2.2 0s-.5 1.6 0 2.2L9.8 12l-5.4 5.4c-.6.6-.6 1.6 0 2.2.3.3.7.4 1.1.4s.8-.1 1.1-.4l5.4-5.4 5.4 5.4c.3.3.7.4 1.1.4s.8-.1 1.1-.4c.6-.6.6-1.6 0-2.2L14.2 12z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});