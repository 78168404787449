define("ember-svg-jar/inlined/badge-follow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.9 12.4c.3-.6.4-1.2.4-1.9 0-2.3-1.6-4.1-3.5-4.1s-3.5 1.9-3.5 4.1c0 .7.2 1.3.4 1.9-2.1.8-3.3 2.4-3.3 4.2 0 .9 1.2 1.3 2.3 1.5 1.1.2 2.5.3 4.1.3 2.9 0 6.4-.3 6.4-1.8 0-1.8-1.2-3.3-3.3-4.2zm-3.1-4.8c1.3 0 2.3 1.3 2.3 2.9s-1 2.9-2.3 2.9c-1.3 0-2.3-1.3-2.3-2.9s1.1-2.9 2.3-2.9zm0 9.6c-3 0-4.8-.4-5.2-.7.1-1.3 1.1-2.4 2.8-3 .6.7 1.5 1.2 2.5 1.2s1.8-.5 2.5-1.2c1.6.6 2.7 1.7 2.7 3-.5.3-2.3.7-5.3.7zM20 7.6h-1.5V6.2c0-.3-.3-.6-.6-.6s-.6.3-.6.6v1.5h-1.5c-.3 0-.6.3-.6.6s.3.6.6.6h1.5v1.5c0 .3.3.6.6.6s.6-.3.6-.6V8.9H20c.3 0 .6-.3.6-.6s-.3-.7-.6-.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "curentColor"
    }
  };
  _exports.default = _default;
});