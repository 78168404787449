define("ember-svg-jar/inlined/badge-mention", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.4 17.6h-3.6c-.7 0-1.2-.5-1.2-1.2v-2.7c0-1.3.2-2.6.7-3.7.5-1.1 1.2-2.3 2.3-3.3l.4-.4 2.7 2.1-.4.5c-.8.9-1.4 1.8-1.6 2.7h.8c.7 0 1.2.5 1.2 1.2v3.7c-.1.6-.6 1.1-1.3 1.1zM16 7.8c-.7.8-1.3 1.7-1.6 2.5-.4 1-.6 2.1-.6 3.3v2.7h3.6v-3.7h-2.3l.1-.7c.2-1.1.7-2.3 1.7-3.5l-.9-.6zm-5.8 9.8H6.6c-.7 0-1.2-.5-1.2-1.2v-2.7c0-1.3.2-2.6.7-3.7.5-1.1 1.2-2.3 2.3-3.3l.4-.4 2.7 2.1-.5.4c-.8.9-1.4 1.8-1.6 2.7h.8c.7 0 1.2.5 1.2 1.2v3.7c0 .7-.5 1.2-1.2 1.2zM8.8 7.8c-.7.8-1.3 1.7-1.6 2.5-.4 1-.6 2.1-.6 3.3v2.7h3.6v-3.7H7.9L8 12c.2-1.1.7-2.3 1.7-3.5l-.9-.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});