define("ember-svg-jar/inlined/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M16.2 7.5L18 4.3c.1-.2.1-.4 0-.6s-.3-.3-.5-.3h-11c-.3 0-.6.3-.6.6v16c0 .3.3.6.6.6s.6-.3.6-.6v-8.4h10.4c.2 0 .4-.1.5-.3s.1-.4 0-.6l-1.8-3.2zm-9.1 2.9V4.6h9.4L15 7.2c-.1.2-.1.4 0 .6l1.5 2.6H7.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "width": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});