define("ember-svg-jar/inlined/add-freestanding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#1589ee\" d=\"M16.8 7.2c-1.3-1.3-3-2-4.8-2-1.8 0-3.5.7-4.8 2s-2 3-2 4.8c0 1.8.7 3.5 2 4.8 2.7 2.7 7 2.7 9.6 0 2.7-2.6 2.7-7 0-9.6z\"/><path fill=\"#FFF\" d=\"M16.3 11.4h-3.7V7.8h-1.2v3.6H7.8v1.2h3.6v3.7h1.2v-3.7h3.7z\"/>",
    "attrs": {
      "height": "24",
      "viewBox": "4 4 16 16",
      "width": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});