define("ember-svg-jar/inlined/badge-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 16.542l-3.24 1.42c-.323.14-.673-.126-.644-.49l.288-3.667-2.291-2.789c-.228-.277-.094-.708.246-.792l3.418-.846 1.825-3.144a.455.455 0 01.796 0l1.825 3.144 3.418.846c.34.084.474.515.246.792l-2.29 2.79.287 3.666c.029.364-.321.63-.643.49z\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-width=\"1.2\"/>",
    "attrs": {
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});