define("ember-svg-jar/inlined/feeds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 2.4H9C7.6 2.4 6.4 3.6 6.4 5v1.4H5C3.6 6.4 2.4 7.6 2.4 9v9c0 1.4 1.2 2.6 2.6 2.6h15c1.4 0 2.6-1.2 2.6-2.6V5c0-1.4-1.2-2.6-2.6-2.6zM3.6 18V9c0-.8.6-1.4 1.4-1.4h1.4V18c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4zm17.8 0c0 .8-.6 1.4-1.4 1.4H7.2c.2-.4.4-.9.4-1.4V5c0-.8.6-1.4 1.4-1.4h11c.8 0 1.4.6 1.4 1.4v13z\"/><path d=\"M19 6.4h-9c-.3 0-.6.3-.6.6s.3.6.6.6h9c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zm0 4h-9c-.3 0-.6.3-.6.6s.3.6.6.6h9c.3 0 .6-.3.6-.6s-.3-.6-.6-.6zm0 4h-9c-.3 0-.6.3-.6.6s.3.6.6.6h9c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});