define("ember-svg-jar/inlined/repost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.4 4.6c-.2-.2-.6-.2-.8 0-.2.2-.2.6 0 .8l1.1 1.1H8.1C5.2 6.6 2.9 9 2.9 12s2.3 5.4 5.2 5.4c.3 0 .6-.3.6-.6s-.3-.6-.6-.6c-2.2 0-4-1.9-4-4.2 0-2.3 1.8-4.2 4-4.2H11L9.6 9.1c-.2.2-.2.6 0 .8.1.1.3.2.4.2.2 0 .3-.1.4-.2l2.7-2.7-2.7-2.6zm5.5 2c-.3 0-.6.3-.6.6s.3.6.6.6c2.2 0 4 1.9 4 4.2 0 2.3-1.8 4.2-4 4.2H13l1.4-1.4c.2-.2.2-.6 0-.8-.2-.2-.6-.2-.8 0l-2.7 2.7 2.7 2.7c.1.1.3.2.4.2.2 0 .3-.1.4-.2.2-.2.2-.6 0-.8l-1.1-1.1h2.6c2.8 0 5.2-2.4 5.2-5.4s-2.3-5.5-5.2-5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});