define("ember-svg-jar/inlined/close-colored", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#EBF6FF\" d=\"M12 4.6c-4.1 0-7.4 3.3-7.4 7.4s3.3 7.3 7.4 7.3 7.3-3.3 7.3-7.3-3.2-7.4-7.3-7.4z\"/><path fill=\"#1589EE\" d=\"M12 3.4c-4.8 0-8.6 3.8-8.6 8.6s3.8 8.7 8.6 8.7 8.7-4 8.7-8.7-3.9-8.6-8.7-8.6zM4.6 12c0-4.1 3.3-7.4 7.4-7.4S19.3 8 19.3 12s-3.2 7.3-7.3 7.3-7.4-3.2-7.4-7.3z\"/><path fill=\"#1589EE\" d=\"M14.8 8.3L12 11.1 9.2 8.3l-.9.9 2.8 2.8-2.8 2.8.9.9 2.8-2.8 2.8 2.8.9-.9-2.8-2.8 2.8-2.8z\"/>",
    "attrs": {
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});