define("ember-svg-jar/inlined/unmute-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.2 20c-.4 0-.7-.1-1.1-.3l-4.2-2.8H7.8c-1 0-1.9-.9-1.9-1.9V9c0-1 .9-1.9 1.9-1.9h3.1l4.2-2.8c.9-.6 2.1-.3 2.6.5.2.3.3.7.3 1.1v12.2c.1 1-.8 1.9-1.8 1.9zM7.8 8.6c-.2 0-.4.2-.4.4v6c0 .3.2.5.5.5h3.3c.1 0 .3 0 .4.1l4.4 2.9c.3.2.7 0 .7-.4V5.9c0-.1 0-.2-.1-.3-.1-.2-.4-.3-.7-.1l-4.4 2.9c-.1.1-.3.1-.4.1H7.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});