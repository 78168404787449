define("ember-svg-jar/inlined/send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M16.3 4.6c-.2-.1-.4-.1-.6 0l-15 9.8c-.2.1-.3.4-.3.6s.2.4.5.5l8.2 1.4 5.3 6.4c.1.1.3.2.5.2h.2c.2-.1.4-.3.4-.5l1.1-17.9c0-.2-.1-.4-.3-.5zm-1.9 16.8l-4.2-5.1 1.8-3.1c.2-.3.1-.7-.2-.8-.3-.2-.7-.1-.8.2l-1.8 3.1-6.5-1.1 12.7-8.4-1 15.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "width": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});