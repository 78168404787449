define("ember-svg-jar/inlined/reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.8 19.7c-.1 0-.1 0 0 0-.4 0-.6-.1-.8-.4-.2-.2-.3-.4-.3-.7V16H6.5c-1.4.1-2.5-1-2.5-2.4V6.5C4 5.1 5.1 4 6.5 4h11.1c1.4 0 2.5 1.1 2.5 2.5v7.1c0 1.4-1.1 2.5-2.5 2.5h-4.2l-3.8 3.4c-.3.1-.6.2-.8.2zM6.5 5.3c-.7 0-1.3.6-1.3 1.3v7.1c0 .7.6 1.3 1.3 1.3h1.8c.3 0 .6.3.6.6v2.9l3.8-3.4c.1-.1.2-.1.4-.1h4.5c.7 0 1.3-.6 1.3-1.3V6.5c0-.7-.6-1.3-1.3-1.3H6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});