define("ember-svg-jar/inlined/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#ebf6ff\" d=\"M16.8 7.2c-1.3-1.3-3-2-4.8-2-1.8 0-3.5.7-4.8 2s-2 3-2 4.8c0 1.8.7 3.5 2 4.8 2.7 2.7 7 2.7 9.6 0 2.7-2.6 2.7-7 0-9.6z\"/><path fill=\"#1589ee\" d=\"M17.7 6.3C16.1 4.8 14.1 4 12 4s-4.1.8-5.7 2.3C4.8 7.9 4 9.9 4 12s.8 4.1 2.3 5.7C7.9 19.2 10 20 12 20s4.1-.8 5.7-2.3c3.1-3.2 3.1-8.2 0-11.4zM7.2 16.8c-1.3-1.3-2-3-2-4.8 0-1.8.7-3.5 2-4.8s3-2 4.8-2c1.8 0 3.5.7 4.8 2 2.7 2.7 2.7 7 0 9.6-2.6 2.7-7 2.7-9.6 0z\"/><path fill=\"#1589ee\" d=\"M16.3 11.4h-3.7V7.8h-1.2v3.6H7.8v1.2h3.6v3.7h1.2v-3.7h3.7z\"/>",
    "attrs": {
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});