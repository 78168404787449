define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.9 12l4.9-4.9c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L12 11.1 7.1 6.2c-.2-.2-.6-.2-.9 0-.2.2-.2.6 0 .9l4.9 4.9-4.9 4.9c-.2.2-.2.6 0 .9.2.2.6.2.9 0l4.9-4.9 4.9 4.9c.2.2.6.2.9 0 .2-.2.2-.6 0-.9L12.9 12z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});