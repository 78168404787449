define("ember-svg-jar/inlined/conversation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.6 4.4H8.7c-1.2 0-2.1.9-2.1 2.1v1H4.5c-1.2 0-2.1.9-2.1 2.1v5.6c0 1.2.9 2.1 2.1 2.1h1.1v2.1c0 .2.1.5.3.7.2.2.4.3.7.3.2 0 .5-.1.7-.3l3.1-2.8h4c1.2 0 2.1-.9 2.1-2.1v-.8h2.1c1.2 0 2.1-.9 2.1-2.1V6.5c0-1.2-1-2.1-2.1-2.1zm-3.4 10.9c0 .5-.4.9-.9.9H10c-.1 0-.3.1-.4.2L6.7 19v-2.2c0-.3-.3-.6-.6-.6H4.5c-.5 0-.9-.4-.9-.9V9.6c0-.5.4-.9.9-.9h9.8c.5 0 .9.4.9.9v5.7zm4.3-2.9c0 .5-.4.9-.9.9h-2.1V9.6c0-1.2-.9-2.1-2.1-2.1H7.8v-1c0-.5.4-.9.9-.9h9.8c.5 0 .9.4.9.9v5.9z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});