define("ember-svg-jar/inlined/poll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7 18.7H6c-.9 0-1.6-.7-1.6-1.7V8c0-.9.7-1.6 1.6-1.6h1c.9 0 1.6.7 1.6 1.6v9c0 .9-.7 1.7-1.6 1.7zM6 7.6c-.2 0-.4.2-.4.4v9c0 .2.2.3.4.3h1c.2 0 .4-.2.4-.3V8c0-.2-.2-.4-.4-.4H6zm7.5 11.1h-2c-.6 0-1.1-.5-1.1-1.2v-12c0-.6.5-1.1 1.1-1.1h2c.6 0 1.2.5 1.2 1.1v12c0 .6-.6 1.2-1.2 1.2zm-1.9-1.4h1.7V5.6h-1.7v11.7zm7.9 1.4h-2c-.6 0-1.2-.5-1.2-1.2v-6c0-.6.5-1.1 1.2-1.1h2c.6 0 1.2.5 1.2 1.1v6c0 .6-.6 1.2-1.2 1.2zm-1.8-1.4h1.7v-5.7h-1.7v5.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});