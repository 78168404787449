define("ember-collection/utils/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = identity;

  function identity(item) {
    let key;
    let type = typeof item;

    if (type === 'string' || type === 'number') {
      key = item;
    } else {
      key = Ember.guidFor(item);
    }

    return key;
  }
});