define("ember-svg-jar/inlined/chevron-down-cropped-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#1589ee\" d=\"M6 5.6c-.3 0-.5-.1-.7-.3L1.1 1.4C.9 1.2.8.8 1.1.6c.2-.2.6-.3.8 0L6 4.4 10.1.6c.2-.2.6-.2.8 0 .2.2.2.6 0 .8L6.7 5.3c-.1.2-.4.3-.7.3zm-.1-1.1zm.2-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 12 6",
      "height": "6",
      "width": "12"
    }
  };
  _exports.default = _default;
});