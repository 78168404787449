define("ember-svg-jar/inlined/unfollow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.6 12.6c.3-.7.5-1.5.5-2.4 0-2.7-1.8-4.8-4.1-4.8s-4.1 2.2-4.1 4.8c0 .8.2 1.6.5 2.3-2.5.9-4 2.8-4 4.9 0 1.1 1.5 1.4 2.7 1.7 1.3.2 3 .4 4.9.4 2.8 0 7.6-.3 7.6-2-.1-2.1-1.6-4-4-4.9zm-3.6-6c1.6 0 2.9 1.6 2.9 3.6s-1.3 3.6-2.9 3.6-2.9-1.6-2.9-3.6S8.4 6.6 10 6.6zm0 11.7c-3.8 0-6-.5-6.4-.9 0-1.7 1.4-3.1 3.5-3.8.7.9 1.8 1.5 2.9 1.5 1.1 0 2.2-.6 2.9-1.4 2.1.7 3.4 2.2 3.4 3.8-.3.3-2.5.8-6.3.8zM21 6.9h-5c-.3 0-.6.3-.6.6s.3.6.6.6h5c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});