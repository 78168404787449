define("ember-arg-types/decorator", ["exports", "ember-arg-types/-private/is-element-descriptor", "ember-arg-types/-private/throw-console-error", "prop-types", "ember-get-config"], function (_exports, _isElementDescriptor, _throwConsoleError, _propTypes, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arg;

  function createGetter(_target, key, descriptor, validator) {
    const defaultValue = (descriptor.get || descriptor.initializer || (() => undefined)).apply(descriptor);

    return {
      get() {
        const argValue = this.args[key];
        const returnValue = argValue !== undefined ? argValue : defaultValue;
        Ember.runInDebug(() => {
          var _config$emberArgTyp;

          const throwErrors = (_config$emberArgTyp = _emberGetConfig.default['ember-arg-types']) === null || _config$emberArgTyp === void 0 ? void 0 : _config$emberArgTyp.throwErrors;
          const shouldThrowErrors = Ember.isNone(throwErrors) ? true : throwErrors;
          (0, _throwConsoleError.default)(() => {
            if (validator) {
              _propTypes.default.checkPropTypes({
                [key]: validator
              }, {
                [key]: returnValue
              }, 'prop', this.constructor.name);
            }
          }, shouldThrowErrors);
        });
        return returnValue;
      }

    };
  }

  function arg(...args) {
    if ((0, _isElementDescriptor.default)(...args)) {
      return createGetter(...args);
    }

    const [validator] = args;
    return function argument(...args) {
      return createGetter(...[...args, validator]);
    };
  }
});