define("ember-svg-jar/inlined/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.5 18.7H5.8V5.3h6.7c.3 0 .6-.3.6-.6s-.3-.6-.6-.6H5.2c-.4-.1-.7.2-.7.5v14.7c0 .3.3.6.6.6h7.4c.3 0 .6-.3.6-.6s-.2-.6-.6-.6z\"/><path d=\"M19.2 11.3l-3-3.4c-.2-.3-.6-.3-.9-.1-.3.3-.3.7-.1.9l2.3 2.6H8.9c-.3 0-.6.3-.6.6s.3.6.6.6h8.6l-2.3 2.6c-.2.3-.2.7.1.9.1.1.3.2.4.2.2 0 .3-.1.5-.2l3-3.4c.2-.2.3-.4.3-.7s-.1-.4-.3-.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});