define("ember-svg-jar/inlined/badge-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.1 18.6c-.3 0-.5-.1-.7-.3-.2-.2-.2-.4-.2-.6v-1.8h-.8c-1.1 0-2-.9-2-2V8.4c0-1.1.9-2 2-2h8.2c1.1 0 2 .9 2 2v5.4c0 1.1-.9 2-2 2h-3l-2.8 2.5c-.2.2-.5.3-.7.3zm-1.7-11c-.4 0-.8.4-.8.8v5.4c0 .5.4.8.8.8h1.9V17l2.7-2.4h3.5c.5 0 .8-.4.8-.8V8.4c0-.5-.4-.8-.8-.8H8.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});