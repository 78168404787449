define("ember-svg-jar/inlined/audio-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.3 24c-.4 0-.7-.1-1.1-.3L9 20.9H5.9C4.9 20.9 4 20 4 19v-6c0-1 .9-1.9 1.9-1.9H9l4.2-2.8c.9-.6 2.1-.3 2.6.5.2.3.3.7.3 1.1v12.2c.1 1-.8 1.9-1.8 1.9zM5.9 12.5c-.2 0-.4.2-.4.4V19c0 .3.2.5.5.5h3.3c.1 0 .3 0 .4.1l4.4 2.9c.3.2.7 0 .7-.4V9.9c0-.1 0-.2-.1-.3-.1-.2-.4-.3-.7-.1l-4.4 2.9c-.1.1-.3.1-.4.1H5.9zM18.4 20.6c-.3 0-.6-.2-.7-.4-.2-.4 0-.8.4-1 1.3-.6 2.1-1.8 2.1-3.2 0-1.4-.8-2.6-2.1-3.2-.4-.2-.5-.6-.4-1s.6-.5 1-.4c1.9.8 3 2.5 3 4.5s-1.1 3.7-3 4.5c-.1.2-.2.2-.3.2z\"/><path d=\"M20.5 24.2c-.3 0-.6-.2-.7-.4-.2-.4 0-.8.4-1 2.8-1.3 4.4-3.8 4.4-6.7 0-3-1.6-5.5-4.4-6.7-.4-.2-.5-.6-.4-1 .2-.4.6-.5 1-.4 3.3 1.5 5.3 4.5 5.3 8.1s-2 6.6-5.3 8.1c-.1-.1-.2 0-.3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "height": "32",
      "width": "32"
    }
  };
  _exports.default = _default;
});