define("ember-concerns/index", ["exports", "ember-concerns/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inject = inject;
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function inject(...args) {
    let calledAsDecorator = (0, _utils.isElementDescriptor)(args);
    let explicitName = calledAsDecorator ? undefined : args[0];
    let computedProperty = {
      get(propertyName) {
        let name = explicitName || propertyName;
        let model = this;
        let factory = (0, _utils.getConcernFactory)(model, name);
        let concern = factory.create({
          model
        });
        return concern;
      }

    };
    let decorator = Ember.computed(computedProperty);

    if (calledAsDecorator) {
      return decorator(args[0], args[1], args[2]);
    } else {
      return decorator;
    }
  }

  class Concern {
    static create() {
      return new this(...arguments);
    }

    constructor({
      model
    }) {
      _defineProperty(this, "model", void 0);

      (false && !(model.willDestroy) && Ember.assert('Concern needs an Ember object as model', model.willDestroy));
      Ember.setOwner(this, Ember.getOwner(model));
      this.model = model;
      (0, _utils._cleanupOnDestroy)(this.model, this, 'destroy', 'the object it lives on was destroyed or unrendered');
    }

    destroy() {}

  }

  var _default = Concern;
  _exports.default = _default;
});