define("ember-user-performance-monitoring/components/monitor-render", ["exports", "ember-user-performance-monitoring/templates/components/monitor-render"], function (_exports, _monitorRender) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const performance = window && window.performance;

  var _default = Ember.Component.extend({
    layout: _monitorRender.default,
    tagName: '',
    userPerformanceMonitoring: Ember.inject.service(),
    _topLevel: Ember.computed.not('parentView'),

    init() {
      (false && !(this.key) && Ember.assert('[monitor-render] This component must be initialized with a key', this.key));

      if (!performance) {
        return;
      }

      this._children = {};
      this._renderMonitoringTree = {
        initTime: Math.round(performance.now()),
        children: new Promise(resolve => {
          this._resolveChildrenPromise = resolve;
        })
      };
      this.renderMonitoringTree = new Promise(resolve => {
        this._resolveTree = resolve;
      });
      this.userPerformanceMonitoring.addRenderMonitor(this.key, this._renderMonitoringTree);

      if (this.parentKey) {
        this.userPerformanceMonitoring.addRenderMonitorChild(this.parentKey, this.key, Ember.RSVP.hash(this._renderMonitoringTree));
      }

      return this._super(...arguments);
    },

    _resolveChildren() {
      this._childrenResolved = true;
      return this._resolveChildrenPromise(Ember.RSVP.hash(this._children));
    },

    addChild(key, treePromise) {
      this._children[key] = treePromise;
    },

    didRender() {
      const result = this._super(...arguments);

      if (!performance) {
        return;
      } // Only call on initial render


      if (this._childrenResolved) {
        return;
      }

      const didRenderTime = Math.round(performance.now());
      const duration = didRenderTime - this._renderMonitoringTree.initTime;
      Object.assign(this._renderMonitoringTree, {
        didRenderTime,
        duration
      });

      this._resolveChildren();

      this._resolveTree(this._renderMonitoringTree);

      if (this._topLevel) {
        this.userPerformanceMonitoring.resolveTopLevelRender(this.renderMonitoringTree);
      }

      return result;
    }

  });

  _exports.default = _default;
});