define("ember-svg-jar/inlined/muted-user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#e1e6ed\" d=\"M2.7 0h26.7C30.8 0 32 1.2 32 2.7v26.7c0 1.5-1.2 2.7-2.7 2.7H2.7C1.2 32 0 30.8 0 29.3V2.7C0 1.2 1.2 0 2.7 0z\"/><path fill=\"#748399\" d=\"M19 21.9c-.3 0-.6-.1-.9-.3l-2-1.3c-.3-.2-.3-.6-.2-.8s.6-.3.8-.2l2 1.3c.2.2.6 0 .6-.3v-3.8c0-.3.3-.6.6-.6s.6.3.6.6v3.8c.1.8-.6 1.6-1.5 1.6zm-6.5-2.7H12c-.9 0-1.6-.7-1.6-1.6v-5c0-.9.7-1.6 1.6-1.6h2.6l3.5-2.4c.7-.5 1.7-.3 2.2.4.2.3.3.6.3.9v1.2c0 .3-.3.6-.6.6s-.6-.3-.6-.6V10c0-.1 0-.2-.1-.2-.1-.2-.4-.2-.6-.1L15 12.2c-.1.1-.2.1-.3.1H12c-.2 0-.4.2-.4.4v5c0 .2.2.4.4.4h.5c.3 0 .6.3.6.6s-.3.5-.6.5zm-1.4 4c-.2 0-.3-.1-.4-.2-.2-.2-.2-.6 0-.8l13-13c.2-.2.6-.2.8 0s.2.6 0 .8l-13 13c-.1.1-.2.2-.4.2z\" class=\"st1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});