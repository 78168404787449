define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.1 10.1L19 7.9V4c0-.3-.3-.6-.6-.6h-2.6c-.3 0-.6.3-.6.6v1.3l-2.3-1.6c-.5-.3-1.1-.3-1.5 0l-9.3 6.5c-.3.2-.5.6-.5 1 0 .7.6 1.3 1.3 1.3h1.6l1 7.6c0 .3.3.5.6.5h12.2c.3 0 .6-.2.6-.5l1-7.6h1.6c.4 0 .8-.2 1-.5.2-.3.3-.6.3-.9-.3-.4-.5-.8-.7-1zm-5.8-5.5h1.4v2.5l-1.4-1V4.6zm-5.8 14.8v-4.8h3v4.8h-3zm10.8-8.2h-2.1c-.3 0-.6.2-.6.5l-1 7.6h-2.9V14c0-.3-.3-.6-.6-.6H9.9c-.3 0-.6.3-.6.6v5.4H6.4l-1-7.6c0-.3-.3-.5-.6-.5H2.7c-.1 0-.1 0-.1-.1l9.3-6.5h.2l9.3 6.6c0-.1-.1-.1-.1-.1z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});