define("ember-bind-helper/helpers/bind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bind = bind;
  _exports.default = void 0;

  function bind([f, ...params], {
    target
  }) {
    if (!f || typeof f !== "function") {
      throw "bind needs to receive at least one argument, a function";
    }

    return f.bind(target, ...params);
  }

  var _default = Ember.Helper.helper(bind);

  _exports.default = _default;
});