define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.6 19.6h-.2L12 17.8l-4.3 1.8c-.2.1-.4.1-.6-.1-.2-.1-.3-.3-.3-.6l.4-4.7-3.1-3.6c-.1 0-.1-.3-.1-.5.1-.2.2-.4.4-.4l4.6-1 2.4-4c.2-.4.8-.4 1.1 0l2.4 4 4.6 1.1c.2.1.4.2.4.4.1.2 0 .4-.1.6l-3.1 3.6.4 4.7c0 .2-.1.4-.3.6 0-.1-.1-.1-.2-.1zM12 16.5h.2l3.6 1.5-.3-3.9c0-.2 0-.3.1-.5l2.6-3-3.8-.9c-.2 0-.3-.1-.4-.3L12 6l-2 3.4c-.1.3-.3.4-.4.4l-3.8.9 2.6 3c.1.1.2.3.1.5L8.1 18l3.6-1.5h.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});