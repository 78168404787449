define("ember-svg-jar/inlined/unmute-conversation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.8 20c-.4 0-.8-.2-1-.5l-2.5-3.2h-.1c-3.4 0-6.2-2.8-6.2-6.2C4 6.8 6.8 4 10.2 4h3.6c3.4 0 6.2 2.8 6.2 6.2 0 2.9-2 5.4-4.8 6v2.5c-.1.7-.7 1.3-1.4 1.3zM10.2 5.5c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7h.5c.2 0 .4.1.6.3l2.5 3.2v-2.8c0-.4.3-.7.6-.7 2.4-.3 4.2-2.3 4.2-4.7 0-2.6-2.1-4.7-4.7-4.7h-3.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});