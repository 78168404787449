define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 7h-2.8V4.9c0-.8-.7-1.5-1.5-1.5h-3.3c-.8 0-1.5.7-1.5 1.5V7h-3c-.4 0-.7.2-.7.6s.3.6.6.6h.7l.7 10.2c.1.9.9 1.6 1.8 1.6h6c.9 0 1.7-.7 1.8-1.7l.7-10.2h.5c.3 0 .6-.3.6-.6S18.3 7 18 7zm-8-2.1c0-.2.2-.3.3-.3h3.3c.2 0 .3.1.3.3V7H10V4.9zm5.6 13.4c0 .3-.3.5-.6.5H9c-.3 0-.6-.2-.6-.5l-.7-10h8.6l-.7 10z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});