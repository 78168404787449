define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M56.9 0H7.1C3.2 0 0 3.2 0 7.1v28.8C0 39.8 3.2 43 7.1 43h49.8c3.9 0 7.1-3.2 7.1-7.1V7.1C64 3.2 60.8 0 56.9 0zm5.8 35.9c0 3.2-2.6 5.8-5.8 5.8H7.1c-3.2 0-5.8-2.6-5.8-5.8V7.1c0-3.2 2.6-5.8 5.8-5.8h49.8c3.2 0 5.8 2.6 5.8 5.8v28.8z\"/><path fill=\"#818C9C\" d=\"M56.9 1.3H7.1c-3.2 0-5.8 2.6-5.8 5.8v28.8c0 3.2 2.6 5.8 5.8 5.8h49.8c3.2 0 5.8-2.6 5.8-5.8V7.1c0-3.2-2.6-5.8-5.8-5.8zM41.7 21.9c-.1.2-.3.4-.4.4l-16.8 8.4c-.5.2-1.1 0-1.3-.4-.2-.1-.2-.3-.2-.4V13.1c0-.6.4-1 1-1 .2 0 .3 0 .4.1l16.8 8.4c.5.3.7.9.5 1.3z\"/><path fill=\"#FFF\" d=\"M41.2 20.6l-16.8-8.4c-.1-.1-.3-.1-.4-.1-.6 0-1 .4-1 1v16.8c0 .2 0 .3.1.4.2.5.8.7 1.3.4l16.8-8.4c.2-.1.4-.3.4-.4.3-.4.1-1-.4-1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 43"
    }
  };
  _exports.default = _default;
});