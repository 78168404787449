define("ember-svg-jar/inlined/pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M7.1 0C3.2 0 0 3.2 0 7.1v28.8C0 39.8 3.2 43 7.1 43h49.8c3.9 0 7.1-3.2 7.1-7.1V7.1C64 3.2 60.8 0 56.9 0m0 1.3c3.2 0 5.8 2.6 5.8 5.8v28.8c0 3.2-2.6 5.8-5.8 5.8H7.1c-3.2 0-5.8-2.6-5.8-5.8V7.1c0-3.2 2.6-5.8 5.8-5.8\"/><path fill=\"#818C9C\" d=\"M7.1 1.3c-3.2 0-5.8 2.6-5.8 5.8v28.8c0 3.2 2.6 5.8 5.8 5.8h49.8c3.2 0 5.8-2.6 5.8-5.8V7.1c0-3.2-2.6-5.8-5.8-5.8\"/><path fill=\"#FFF\" d=\"M29.2 31.1h-2.6c-.6 0-1-.5-1-1V12.9c0-.6.4-1 1-1h2.6c.6 0 1 .4 1 1v17.3c0 .5-.5.9-1 .9zm9.2-1V12.9c0-.6-.4-1-1-1h-2.6c-.5 0-1 .4-1 1v17.3c0 .5.5 1 1 1h2.6c.6-.1 1-.5 1-1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 43"
    }
  };
  _exports.default = _default;
});