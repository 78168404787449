define("ember-router-scroll/services/router-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let RouterScroll = (_class = (_temp = class RouterScroll extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "key", void 0);

      _defineProperty(this, "targetElement", void 0);

      _defineProperty(this, "scrollElement", 'window');

      _defineProperty(this, "isFirstLoad", true);

      _defineProperty(this, "preserveScrollPosition", false);

      _defineProperty(this, "delayScrollTop", false);

      _defineProperty(this, "scrollWhenPainted", false);

      _defineProperty(this, "scrollWhenIdle", false);
    }

    get isFastBoot() {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }

    init(...args) {
      super.init(...args);

      this._loadConfig();

      Ember.set(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      });
    }

    unsetFirstLoad() {
      Ember.set(this, 'isFirstLoad', false);
    }

    update() {
      if (Ember.get(this, 'isFastBoot') || Ember.get(this, 'isFirstLoad')) {
        return;
      }

      const scrollElement = Ember.get(this, 'scrollElement');
      const targetElement = Ember.get(this, 'targetElement');
      const scrollMap = Ember.get(this, 'scrollMap');
      const key = Ember.get(this, 'key');
      let x;
      let y;

      if (targetElement) {
        let element = document.querySelector(targetElement);

        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop; // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen

          Ember.set(scrollMap, 'default', {
            x,
            y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        let element = document.getElementById(scrollElement.substring(1));

        if (element) {
          x = element.scrollLeft;
          y = element.scrollTop;
        }
      } // only a `key` present after first load


      if (key && 'number' === Ember.typeOf(x) && 'number' === Ember.typeOf(y)) {
        Ember.set(scrollMap, key, {
          x,
          y
        });
      }
    }

    _loadConfig() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.routerScroll) {
        const scrollElement = config.routerScroll.scrollElement;
        const targetElement = config.routerScroll.targetElement;
        (false && !(!(scrollElement && targetElement)) && Ember.assert('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));

        if ('string' === Ember.typeOf(scrollElement)) {
          Ember.set(this, 'scrollElement', scrollElement);
        }

        if ('string' === Ember.typeOf(targetElement)) {
          Ember.set(this, 'targetElement', targetElement);
        }

        const {
          scrollWhenPainted = false,
          scrollWhenIdle = false,
          delayScrollTop = false
        } = config.routerScroll;
        Ember.set(this, 'delayScrollTop', delayScrollTop);
        Ember.set(this, 'scrollWhenPainted', scrollWhenPainted);
        Ember.set(this, 'scrollWhenIdle', scrollWhenIdle);
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "isFastBoot", [Ember.computed], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype)), _class);
  Object.defineProperty(RouterScroll.prototype, 'position', {
    configurable: true,

    get() {
      const scrollMap = Ember.get(this, 'scrollMap');
      const stateUuid = Ember.get(window, 'history.state.uuid');
      Ember.set(this, 'key', stateUuid);
      const key = Ember.get(this, 'key') || '-1';
      return Ember.get(scrollMap, key) || scrollMap.default;
    }

  });
  var _default = RouterScroll;
  _exports.default = _default;
});