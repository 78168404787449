define("ember-svg-jar/inlined/placeholder-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#ebf6ff\" d=\"M20.5 23c4.1 0 7.5-3.4 7.5-7.5v-4C28 7.4 24.6 4 20.5 4S13 7.4 13 11.5v4c0 4.1 3.4 7.5 7.5 7.5zM35 34c0-5.5-4.5-10-10-10-.3 2.2-2.2 4-4.5 4s-4.3-1.8-4.5-4h-1C9.5 24 5 28.5 5 34v2h30v-2z\"/><path fill=\"#2E405C\" d=\"M36 0H4C1.8 0 0 1.8 0 4v32c0 2.2 1.8 4 4 4h32c2.2 0 4-1.8 4-4V4c0-2.2-1.8-4-4-4zM13 11.5C13 7.4 16.4 4 20.5 4S28 7.4 28 11.5v4c0 4.1-3.4 7.5-7.5 7.5S13 19.6 13 15.5v-4zM35 36H5v-2c0-5.5 4.5-10 10-10h1c.2 2.2 2.2 4 4.5 4s4.2-1.8 4.5-4c5.5 0 10 4.5 10 10v2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});