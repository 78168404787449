define("ember-user-performance-monitoring/templates/components/render-performance-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5nnlcsFL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-user-performance-monitoring/templates/components/render-performance-monitor.hbs"
    }
  });

  _exports.default = _default;
});