define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.3 18.2H4c-.6 0-1-.4-1-1 0-.2.1-.4.2-.6l5.3-7.3c.2-.2.4-.4.7-.4.3 0 .5 0 .7.2.1.1.2.1.2.2l3.6 5 2-2.9c.3-.5.9-.6 1.4-.2.1.1.2.1.2.2l3.7 5.3c.2.2.2.5.2.7 0 .3-.2.5-.4.6-.1.1-.3.2-.5.2zm-16.1-.9zm.4-.4h15.1l-3.1-4.4-2.8 4-4.5-6.1-4.7 6.5zm9.5-7.3c-1.5 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6c1.5 0 2.7 1.2 2.7 2.6s-1.2 2.6-2.7 2.6zm0-4c-.7 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4 1.3-.6 1.3-1.4-.5-1.4-1.3-1.4z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});