define("ember-svg-jar/inlined/unavailable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 39C9.5 39 1 30.5 1 20S9.5 1 20 1s19 8.5 19 19-8.5 19-19 19zm0-36C10.6 3 3 10.6 3 20s7.6 17 17 17 17-7.6 17-17S29.4 3 20 3z\"/><path d=\"M28 21H12c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "40",
      "width": "40",
      "viewBox": "0 0 40 40",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});