define("ember-svg-jar/inlined/close-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.7 13.5c.3.3.3.9 0 1.2s-.9.3-1.2 0L12 13.2l-3.5 3.5c-.3.3-.9.3-1.2 0s-.3-.9 0-1.2l3.5-3.5-3.5-3.5c-.3-.4-.3-.9 0-1.2s.9-.3 1.2 0l3.5 3.5 3.5-3.5c.3-.3.9-.3 1.2 0s.3.9 0 1.2L13.2 12l3.5 3.5z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});