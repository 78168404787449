define("ember-collection/components/ember-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NaVZLfPu",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[5,[27,[26,7,\"BlockHead\"],[]],null,[[\"content-size\",\"scroll-left\",\"scroll-top\",\"scrollChange\",\"clientSizeChange\"],[[27,[26,6,\"Expression\"],[]],[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[31,113,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"scrollChange\"],null],[31,154,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"clientSizeChange\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[10],[5,[27,[26,2,\"BlockHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[9,\"div\",true],[20,\"style\",[27,[24,1],[\"style\"]],null],[10],[16,2,[[27,[24,1],[\"item\"]],[27,[24,1],[\"index\"]]]],[11]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_cells\",\"-track-array\",\"each\",\"action\",\"_scrollTop\",\"_scrollLeft\",\"_contentSize\",\"ember-native-scrollable\"]}",
    "meta": {
      "moduleName": "ember-collection/components/ember-collection/template.hbs"
    }
  });

  _exports.default = _default;
});