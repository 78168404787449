define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.9 12.4c-.3 0-.6.3-.6.6v5.7H5.8V13c0-.3-.3-.6-.6-.6s-.7.3-.7.6v6.3c0 .3.3.6.6.6h13.7c.3 0 .6-.3.6-.6V13c.1-.3-.2-.6-.5-.6z\"/><path d=\"M7.7 9.3l3.7-3.4v9.3c0 .3.3.6.6.6s.6-.3.6-.6V5.9l3.7 3.4c.1.1.3.2.4.2.2 0 .3-.1.5-.2.2-.3.2-.7 0-.9l-4.4-4.1c-.4-.4-1.2-.4-1.5 0L6.8 8.4c-.2.2-.2.6 0 .9.2.2.6.2.9 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});