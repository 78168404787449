define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 10.4h-.4V8c0-2-1.6-3.6-3.6-3.6S8.4 6 8.4 8v2.4H8c-.9 0-1.6.7-1.6 1.6v6c0 .9.7 1.6 1.6 1.6h8c.9 0 1.6-.7 1.6-1.6v-6c0-.9-.7-1.6-1.6-1.6zM9.6 8c0-1.3 1.1-2.4 2.4-2.4s2.4 1.1 2.4 2.4v2.4H9.6V8zm6.8 10c0 .2-.2.4-.4.4H8c-.2 0-.4-.2-.4-.4v-6c0-.2.2-.4.4-.4h8c.2 0 .4.2.4.4v6z\"/><path d=\"M12 13.5c-.6 0-1 .4-1 1 0 .4.2.7.5.8v1.2c0 .3.2.5.5.5s.5-.2.5-.5v-1.2c.3-.2.5-.5.5-.8 0-.6-.4-1-1-1z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});